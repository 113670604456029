import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useIsMobileView } from '../../utils/responsive';

import { Card, CardContent, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import MeliPayLogo from "../../assets/images/svg/mercadopago.png";
import PaypalLogo from "../../assets/images/svg/paypal.png";
import WebpayLogo from "../../assets/images/svg/webpay.png";
import { DesktopTickets } from './tickets/desktopTickets';
import { MobileTickets } from './tickets/mobileTickets';
import { PurchaseDialog } from '../payment/purchaseDialog';
import TransbankHorizontalLogo from "../../assets/images/svg/transbank-horizontal.png";
import FormPayment from '../payment/form/payment';
import { IWinner } from '../../store/interfaces/IWinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types/rootState';


export interface Ticket {
  id: number;
  label: string;
}

const tickets: Ticket[] = [
  { id: 0, label: 'Ingresar tickets' },
  { id: 5, label: '5 TICKET' },
  { id: 10, label: '10 TICKETS' },
  { id: 20, label: '20 TICKETS' },
];


const paymentMethods = [
  {
    value: "transbank",
    label: "Webpay",
    icon: <img
      style={{
        height: "40px",
      }}
      src={WebpayLogo}
      alt=""
      key={'transbank'}
    />,
    info: "Será redirigido a webpay para finalizar su compra",
  },
  {
    value: "paypal",
    label: "Paypal",
    icon: <img
      style={{
        height: "40px",
      }}
      src={PaypalLogo}
      alt=""
      key={'paypal'}
    />,
    info: "-",
  },
  {
    value: "mercadopago",
    label: "Red Compra",
    icon: <img
      style={{
        height: "50px",
      }}
      src={MeliPayLogo}
      alt=""
      key={'mercadopago'}
    />,
    info: "-",
  },
];


interface RaffleTicketsProps {
  ticketPrice: number;
  ticketPriceUSD: number;
  availablePaymentMethods: string[];
  limit: number;
  raffleType: string;
  raffleId: string;
}

const RaffleTickets = ({ ticketPrice, ticketPriceUSD,  availablePaymentMethods, limit,  raffleId, raffleType }: RaffleTicketsProps) => {

  
  const isLoggedIn = useSelector(({ authentication }: RootState) => authentication.isLoggedIn);
  const [open, setOpen] = useState(true);
  const isMobile = useIsMobileView();
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(tickets[0]);
  const [ticketQuantity, setTicketQuantity] = useState<number | null>(null);
  const [minPurchase, setMinPurchase] = useState<number>(limit === 100 || raffleType === 'promo' ? 1 : 2);
  const [desktopAdditions, setDesktopAdditions] = useState<number[]>([1]);
  const [additions, setAdditions] = useState<number[]>([1]);

  useEffect(() => {
    if (raffleType === 'raffle') {
      setDesktopAdditions([2, 10, 20, 50, 100])
      setAdditions([2, 10, 20, 50, 100])

      if (raffleId === '0720683b-c3e1-4c04-9c5c-172ec6160578') {
        setDesktopAdditions([3, 10, 20, 50, 100])
        setAdditions([3, 10, 20, 50, 100])
      }
    }    
  }, []);

  useEffect(() => {
    
    if (raffleId === '0720683b-c3e1-4c04-9c5c-172ec6160578') {
      setMinPurchase(3)
      setDesktopAdditions([3, 10, 20, 50, 100])
      setAdditions([3, 10, 20, 50, 100])
    }
  }, []);


  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let parsedValue: number | null = parseInt(value);
    const limitPurchase = raffleType === 'promo' ? 1 : limit === 10000 ? 500 : limit * 0.25;

    if (isNaN(parsedValue) || parsedValue < 0) {
      parsedValue = null;
    } else if (parsedValue > limitPurchase) {
      enqueueSnackbar(`El limite de compra para esta rifa es ${limitPurchase}!`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "left",
          vertical: "top"
        },
      })
      parsedValue = limitPurchase;
    } else if (parsedValue < minPurchase) {
      enqueueSnackbar(`El mínimo de compra para la rifa es ${minPurchase}!`, {
        variant: "warning",
        anchorOrigin: {
          horizontal: "left",
          vertical: "top"
        },
      })
    }
    setTicketQuantity(parsedValue);
  };

  const handleChange = (event: Ticket) => {
    const founded = tickets.find((ticket) => ticket.id === event.id);
    if (founded !== undefined) {
      setSelectedTicket(founded);
    }
    
    if (event.id !== 0) {
      setTicketQuantity(event.id);
    }
  }

  const sumTicketQuantity = (event: number) =>  {
    const limitPurchase = raffleType === 'promo' ? 1 : limit === 10000 ? 500 : limit * 0.25;
    if (ticketQuantity) {
      let parsedValue: number | null = ticketQuantity + event;
      if (isNaN(parsedValue) || parsedValue < 0) {
        parsedValue = null;
      } else if (parsedValue > limitPurchase) {
        enqueueSnackbar(`El limite de compra es ${limitPurchase}!`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "left",
            vertical: "top"
          },
        })
        return
      }

      setTicketQuantity(ticketQuantity + event);
    } else {
      setTicketQuantity(event);
    }
  }

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
  React.useState<string | null>(null);
const [purchaserData, setPurchaserData] = React.useState<IWinner>({
  name: "",
  email: "",
  phone: "",
});

const handlePaymentMethodSelect = (method: string) => {
  setOpen(false)
  setSelectedPaymentMethod((prevMethod) =>
    prevMethod === method ? null : method
  );
};



  return (
    <Box paddingX={4}>
      {ticketQuantity && isLoggedIn && selectedPaymentMethod && <PurchaseDialog ticketQuantity={ticketQuantity} raffleId={raffleId} isMobile={isMobile} open={open} setOpen={setOpen} totalAmount={ticketQuantity * ticketPrice} totalAmountUSD={ticketQuantity * ticketPriceUSD}  availablePaymentMethods={availablePaymentMethods} />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            {!isMobile ? (
              <>
              <Card sx={{
                width: '100%',
              }}>
                <CardContent>
                  <Box>
                    <MobileTickets
                    additions={desktopAdditions}
                    handleChangeQuantity={handleChangeQuantity}
                    sumTicketQuantity={sumTicketQuantity}
                    ticketQuantity={ticketQuantity}
                    />
                  </Box>
                </CardContent>
              </Card>
              </>
            ) : (
              <MobileTickets
                additions={additions}
                handleChangeQuantity={handleChangeQuantity}
                sumTicketQuantity={sumTicketQuantity}
                ticketQuantity={ticketQuantity}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
            paddingY={2}
            paddingTop={isMobile ? 2 : 6}
            width="100%"
          >
    
          
            {ticketQuantity && ticketQuantity > 0 && <Box p={8}> <Typography color={"primary"} variant='h4' fontWeight={"bold"}>
                  TOTAL: {`${(ticketQuantity || 0)} TICKET`}
            </Typography>
            </Box>}

            <FormPayment 
                minPurchase={minPurchase}
                availablePaymentMethods={availablePaymentMethods}
                handlePaymentMethodSelect={handlePaymentMethodSelect}
                purchaserData={purchaserData}
                raffleId={raffleId}
                selectedPaymentMethod={'transfer'}
                setOpen={setOpen}
                setPurchaserData={setPurchaserData}
                ticketQuantity={ticketQuantity || 0} 
                totalAmount={(ticketQuantity || 0) * ticketPrice} 
                totalAmountUSD={(ticketQuantity || 0) * ticketPriceUSD}
                />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RaffleTickets;
